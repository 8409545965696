import React from 'react'
import Image from 'components/Image'
import Swiper from 'swiper'

class Carousel extends React.Component {
  constructor(props) {
    super(props)
    this.state = { displayPrevious: false, displayNext: true }
  }
  componentDidMount() {
    let carousel
    let carousels = new Swiper('.carousel', {
      effect: 'fade',
      speed: 1500,
      fadeEffect: {
        crossFade: true,
      },
      slidesPerView: 1,
      simulateTouch: false,
      autoplay: {
        delay: 5000,
      },
      navigation: {
        nextEl: '.carousel__control--next',
        prevEl: '.carousel__control--prev',
      },
      pagination: {
        el: '.carousel__pagination',
        type: 'bullets',
        bulletClass: 'carousel__pagination-bullet',
        bulletActiveClass: 'carousel__pagination-bullet--active',
        clickable: true,
      },
    })

    // During switching pages, the number of Swiper instances will multiply.
    // We loop through non-current instances and destroy them, whilst retaining a reference to the latest one.
    if (Array.isArray(carousels)) {
      carousel = carousels.shift()
      carousels.forEach(oldCarousel => oldCarousel.destroy())
    } else {
      carousel = carousels
    }

    carousel.on('slideChange', event => {
      const slideNumber = carousel.activeIndex + 1,
        totalSlides = carousel.slides.length
      switch (slideNumber) {
        case 1:
          // check if this is the first slide in the list, hide previous button,
          this.setState({ displayPrevious: false, displayNext: true })
          break
        case totalSlides:
          // check if this is the last slide in the list, hide next button
          this.setState({ displayPrevious: true, displayNext: false })
          break
        default:
          // default setting for slides which aren't first or last in the list and display prev/next button
          this.setState({ displayPrevious: true, displayNext: true })
      }
    })
  }

  render() {
    const { items } = this.props
    return (
      <div className="carousel relative z-10">
        <div className="swiper-wrapper">
          {items.map((item, index) => (
            <div key={index} className="swiper-slide w-full">
              {(item.image1.localFile || item.image1.url) && (
                <div className="ratio ratio--1/2 z-10">
                  <div className="absolute inset-0">
                    <Image
                      className="w-ful h-full"
                      src={item.image1.url}
                      fluid={item.image1.localFile?.childImageSharp?.fluid}
                      alt={item.image1.alt}
                    />
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
        {items.length > 1 && (
          <>
            <div className="carousel__pagination absolute w-full mb-3 md:mb-8 lg:mb-12 text-center z-50 outline-none"></div>
            <div
              className={`carousel__control carousel__control--prev absolute left-0 ml-5 xl:ml-8 cursor-pointer transition-opacity outline-none z-50 ${
                this.state.displayPrevious
                  ? 'opacity-100'
                  : 'opacity-0 pointer-events-none'
              }`}
            >
              <svg
                className="carousel__control-icon hidden md:inline-block md:h-10 xl:h-15 md:w-10 xl:w-15 text-white hover:text-teal transition-color"
                viewBox="0 0 61 62.5"
              >
                <path
                  className="fill-current"
                  d="M34.12,44.13,21.23,31.25,34.12,18.37l1.76,1.76L24.77,31.25,35.88,42.37Z"
                />
                <path
                  className="text-teal fill-current"
                  d="M30.5,62.5C13.68,62.5,0,48.48,0,31.25S13.68,0,30.5,0,61,14,61,31.25,47.32,62.5,30.5,62.5Zm0-60c-15.44,0-28,12.9-28,28.75S15.06,60,30.5,60s28-12.9,28-28.75S45.94,2.5,30.5,2.5Z"
                />
              </svg>
            </div>
            <div
              className={`carousel__control carousel__control--next absolute right-0  mr-5 xl:mr-8 cursor-pointer transition-opacity outline-none z-50 ${
                this.state.displayNext
                  ? 'opacity-100'
                  : 'opacity-0 pointer-events-none'
              }`}
            >
              <svg
                className="carousel__control-icon hidden md:inline-block md:h-10 xl:h-15 md:w-10 xl:w-15 text-white hover:text-teal transition-color"
                viewBox="0 0 61 62.5"
              >
                <path
                  className="fill-current"
                  d="M34.12,44.13,21.23,31.25,34.12,18.37l1.76,1.76L24.77,31.25,35.88,42.37Z"
                />
                <path
                  className="text-teal fill-current"
                  d="M30.5,62.5C13.68,62.5,0,48.48,0,31.25S13.68,0,30.5,0,61,14,61,31.25,47.32,62.5,30.5,62.5Zm0-60c-15.44,0-28,12.9-28,28.75S15.06,60,30.5,60s28-12.9,28-28.75S45.94,2.5,30.5,2.5Z"
                />
              </svg>
            </div>
          </>
        )}
      </div>
    )
  }
}

export default Carousel
