import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { useNavigationLabel } from 'utils/navigation'
import { mergePrismicPreviewData } from 'gatsby-source-prismic'

import Layout from 'components/Layout'
import ContentWithImage from 'components/Content/ContentWithImage'
import RoomFeatures from 'components/Rooms/RoomsFeatures'
import ContentAppInformation from 'components/Content/ContentAppInformation'
import Reviews from 'components/Reviews'
import Carousel from 'components/Carousel'
import LinkPanel from 'components/LinkPanel'

const IS_BROWSER = typeof window !== 'undefined'

const RoomDetailPage = ({ location, data: staticData, pageContext }) => {
  const previewData = IS_BROWSER && window.__PRISMIC_PREVIEW_DATA__
  const data = mergePrismicPreviewData({ staticData, previewData })
  const roomsPage = data.allPrismicRooms.edges[0].node.data

  const {
    breadcrumb: { crumbs },
  } = pageContext

  return (
    <Layout
      heroImage={roomsPage.hero}
      crumbs={crumbs}
      crumbLabel={useNavigationLabel(pageContext.uid)}
      metaTitle={roomsPage.meta_title}
      metaDescription={roomsPage.meta_description}
      pageTitle={roomsPage.title}
      location={location}
    >
      {roomsPage.section_1_heading && (
        <div className="mb-1">
          <ContentWithImage
            heading={roomsPage.section_1_heading}
            text={roomsPage.section_1_rich_text}
            button={roomsPage.section_1_button}
            buttonText={roomsPage.section_1_button_text}
            buttonColour={'Teal'}
            image={roomsPage.section_1_image}
          />
        </div>
      )}

      {roomsPage.section_2_heading && (
        <div className="mb-1">
          <RoomFeatures
            heading={roomsPage.section_2_heading}
            features={roomsPage.section_2_features}
            buttonPrimaryLink={roomsPage.section_2_button_1.uid}
            buttonPrimaryText={roomsPage.section_2_button_1_text}
            buttonSecondaryLink={roomsPage.section_2_button_2.uid}
            buttonSecondaryText={roomsPage.section_2_button_2_text}
          />
        </div>
      )}

      {roomsPage.section_3_image.length > 0 && (
        <div className="mb-1">
          <Carousel items={roomsPage.section_3_image} />
        </div>
      )}

      {roomsPage.section_4_heading && (
        <div className="mb-1">
          <ContentAppInformation
            heading={roomsPage.section_4_heading}
            text={roomsPage.section_4_rich_text}
            button={roomsPage.section_4_button}
            buttonText={roomsPage.section_4_button_text}
            image={roomsPage.section_4_image}
            features={roomsPage.section_4_features}
          />
        </div>
      )}

      <Reviews />

      {roomsPage.section_5_heading && (
        <div className="container">
          <div className="max-w-4xl mx-auto">
            <h2 className="text-xl md:text-2xl lg:text-4xl font-semibold leading-tight my-6 md:my-8 lg:my-10 text-center">
              {roomsPage.section_5_heading}
            </h2>
          </div>
        </div>
      )}

      {roomsPage.section_5_panels.length > 0 && (
        <div className="flex flex-wrap -mx-1">
          {roomsPage.section_5_panels.map((item, index) => {
            const linkedDocument = item.panel_link.document
            const heroImg = linkedDocument && linkedDocument.data.hero
            return (
              heroImg &&
              (heroImg.localFile || heroImg.url) && (
                <LinkPanel
                  key={index}
                  link={item.panel_link}
                  title={item.panel_title}
                  img={heroImg}
                  alt={heroImg.alt}
                />
              )
            )
          })}
        </div>
      )}
    </Layout>
  )
}

RoomDetailPage.propTypes = {
  location: PropTypes.object.isRequired,
}

export const roomsPageQuery = graphql`
  query roomByUid($uid: String) {
    allPrismicRooms(filter: { uid: { eq: $uid } }) {
      edges {
        node {
          data {
            meta_title
            meta_description
            section_1_button {
              uid
              url
              link_type
            }
            section_1_button_text
            section_1_image {
              localFile {
                childImageSharp {
                  fluid(quality: 90) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
            section_1_heading
            section_1_rich_text {
              html
            }
            section_2_button_1 {
              uid
              url
              link_type
            }
            section_2_button_1_text
            section_2_button_2 {
              uid
              url
              link_type
            }
            section_2_button_2_text
            section_2_features {
              feature_description {
                html
              }
              feature_name
              feature_icon
            }
            section_2_heading
            section_3_image {
              image1 {
                localFile {
                  url
                  childImageSharp {
                    fluid(quality: 90) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
            }
            section_4_button {
              uid
              url
              link_type
            }
            section_4_button_text
            section_4_features {
              feature_description
              feature_icon {
                localFile {
                  url
                  childImageSharp {
                    fluid(quality: 90) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
            }
            section_4_heading
            section_4_rich_text {
              html
            }
            section_4_image {
              localFile {
                childImageSharp {
                  fluid(quality: 90) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
            hero {
              localFile {
                childImageSharp {
                  fluid(quality: 90) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
            image2 {
              localFile {
                childImageSharp {
                  fluid(quality: 90) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
            section_5_heading
            section_5_panels {
              panel_title
              panel_link {
                uid
                url
                link_type
                document {
                  ... on PrismicRooms {
                    id
                    data {
                      summary
                      title
                      hero {
                        alt
                        localFile {
                          childImageSharp {
                            fluid(quality: 90) {
                              ...GatsbyImageSharpFluid_noBase64
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            summary
            title
          }
        }
      }
    }
  }
`

export default RoomDetailPage
